import React, {useMemo} from 'react'
import Head from 'next/head'

function HomePageSEO({organisationData, meta, websiteSchemaData}: any) {
  const {
    title = '',
    description = '',
    keywords = '',
    noIndex = false,
    canonical = '',
  } = meta || {}

  const structuredOrganisationData = useMemo(() => {
    const {
      name = '',
      description = '',
      url = '',
      logo = '',
      sameAs = '',
      founder = ['Revant Bhate', 'Dhyanesh Shah'],
      foundingDate = '2019',
      telephone = '',
      address = {
        streetAddress: '',
        addressLocality: '',
        addressRegion: '',
        postalCode: '',
        addressCountry: '',
      },
    } = organisationData || {}

    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      '@additonalType': 'https://schema.org/MedicalOrganization',
      name: name,
      Description: description,
      url: url,
      Logo: logo,
      sameAs: [...sameAs],
      founder: [
        {'@type': 'Person', name: founder[0]},
        {'@type': 'Person', name: founder[1]},
      ],
      foundingDate: foundingDate,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: telephone,
          contactType: 'customer service',
        },
      ],
      Address: {
        type: 'PostalAddress',
        streetAddress: address?.streetAddress,
        addressLocality: address?.addressLocality,
        addressRegion: address?.addressRegion,
        postalCode: address?.postalCode,
        addressCountry: address?.addressCountry,
      },
    }
  }, [organisationData])

  const structuredWebsiteData = useMemo(() => {
    const {url = ''} = websiteSchemaData || {}
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: url,
    }
  }, [websiteSchemaData])

  return (
    <div>
      <Head>
        {noIndex && <meta name="robots" content="noindex" />}
        <title>{title || ''}</title>
        {description && <meta name="description" content={description}></meta>}
        {canonical && <link rel="canonical" href={canonical} />}
        {keywords && <meta name="keywords" content={keywords}></meta>}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredWebsiteData),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredOrganisationData),
          }}
        />
      </Head>
    </div>
  )
}

export default HomePageSEO
