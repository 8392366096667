import React, {useEffect} from 'react'
import {recordToExternalNudge} from 'src/analytics/externalNudge'
import {Typography} from 'src/components/shared/src/ComponentsV2/common/TypographyV2'
import styled from 'styled-components'
import useNudgeAnalytics from './trackNudgeAnalytics'

const StyledContainer = styled.div`
  max-width: 100vw;

  .widget-header {
    padding-left: var(--dls-size-16);
  }

  .widget-title {
  }
  @media screen and (min-width: 768px) {
    max-width: 1124px;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }

  #nudge_stories {
    @media screen and (max-width: 768px) {
      width: 100vw;
    }
    @media screen and (min-width: 768px) {
      justify-content: center;
    }
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

interface NudgeNowProps {
  nudgeId: string
  title?: string
}
function NudgeNow({nudgeId, title}: NudgeNowProps) {
  useEffect(() => {
    recordToExternalNudge(nudgeId, {nudge_id: nudgeId})
  }, [nudgeId])
  useNudgeAnalytics()

  return (
    <StyledContainer>
      {title ? (
        <div className="widget-header">
          <Typography variant="heading-sm-bold" customClassName="widget-title">
            {title}
          </Typography>
        </div>
      ) : null}
      <div>
        <div id="nudge_stories"></div>
      </div>
    </StyledContainer>
  )
}

export default NudgeNow
